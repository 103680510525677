import { component } from '../decorators';


@component('hoverList')
class HoverList {
    constructor(element) {
        this.element = element;

        const items = this.element.querySelectorAll('[js-hover-list-item]');
        items.forEach(item => {
            item.addEventListener('mouseenter', e => {
                const index = e.currentTarget.getAttribute('js-hover-list-item');
                this.activateItem(index);
            });
        });
    }

    activateItem(index) {
        const items = this.element.querySelectorAll('[js-hover-list-item]');
        items.forEach(item => item.classList.remove('is-active'));

        const item = this.element.querySelector(`[js-hover-list-item="${index}"]`);
        item.classList.add('is-active');

        const contentList = this.element.querySelectorAll('[js-hover-list-content]');
        contentList.forEach(content => content.style.display = 'none');

        const content = this.element.querySelector(`[js-hover-list-content="${index}"]`);
        content.style.display = '';
    }
};

export default HoverList;
