import { component } from '../decorators';
import { findComponent } from '../utils';
import NavSidebar from './nav-sidebar';


@component('navTrigger')
class NavTrigger {
  constructor(element) {
    element.addEventListener('click', function () {
      findComponent(NavSidebar).open();
    });
  }
};

export default NavTrigger;
