import { component } from '../decorators';
import Vivus from 'vivus';


@component('hero')
class Hero {
  constructor(element) {
    const svg = element.querySelector('svg');
    new Vivus(svg, {
      duration: 300
    });
  }
};

export default Hero;
