import { component } from '../decorators';
import Vivus from 'vivus';


@component('circle')
class Circle {
  constructor(element) {
    new Vivus(element, {
      duration: 200,
      pathTimingFunction: function (t) {
        return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t;
      }
    });
  }
};

export default Circle;
