import { component } from '../decorators';
import { findComponent } from '../utils';
import Body from './body';


@component('toc')
class Toc {
    constructor(element) {
        this.element = element;

        this.sectionNumber = this.element.querySelector('[js-toc-section-number]');
        this.progressBar = this.element.querySelector('[js-toc-progress-bar]');

        const nextBtn = this.element.querySelector('[js-toc-next-btn]');
        nextBtn.addEventListener('click', this.next.bind(this));
        
        const prevBtn = this.element.querySelector('[js-toc-prev-btn]');
        prevBtn.addEventListener('click', this.prev.bind(this));

        this.anchors = Array.from(this.element.querySelectorAll('[js-toc-anchor]')).map(el => el.getAttribute('href'));

        this.currentIndex = 0;
    }

    setProgress(newValue) {
        this.progressBar.style.height = `${ newValue * 100 }%`;
    }

    updateSectionNumber() {
        setTimeout(() => {
            const activeItem = this.element.querySelector('.is-active');
            if (activeItem) {
                const activeSection = activeItem.getAttribute('href');
                const sectionNumber = this.anchors.indexOf(activeSection) + 1;
                if (sectionNumber < 10) {
                    this.sectionNumber.textContent = `0${sectionNumber}`;
                } else {
                    this.sectionNumber.textContent = `${sectionNumber}`;
                }
            }
        });
    }

    next() {
        let nextIndex = this.currentIndex;

        const activeItem = this.element.querySelector('.is-active');
        if (activeItem) {
            const activeSection = activeItem.getAttribute('href');
            const activeIndex = this.anchors.indexOf(activeSection);
            nextIndex = activeIndex + 1;
        }

        if (nextIndex < this.anchors.length) {
            const nextSection = this.anchors[nextIndex];

            const body = findComponent(Body);
            body.scrollTo(nextSection);

            this.currentIndex = nextIndex;
        }
    }

    prev() {
        let prevIndex = this.currentIndex;

        const activeItem = this.element.querySelector('.is-active');
        if (activeItem) {
            const activeSection = activeItem.getAttribute('href');
            const activeIndex = this.anchors.indexOf(activeSection);
            prevIndex = activeIndex - 1;
        }

        if (prevIndex >= 0) {
            const prevSection = this.anchors[prevIndex];

            const body = findComponent(Body);
            body.scrollTo(prevSection);

            this.currentIndex = prevIndex;
        }
    }
};

export default Toc;
