import { component } from '../decorators';
import { Timer, random } from '../utils';


@component('bouncingBackground')
class BouncingBackground {
  constructor(element, options) {
    this.element = element;
    this.chartsAmount = 12;
    this.grav = 0.5;

    this.canvas = document.createElement('canvas');
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = 0;
    this.canvas.style.left = 0;
    this.canvas.style.zIndex = -1;
    this.element.appendChild(this.canvas);

    this.ctx = this.canvas.getContext('2d');

    this.resize();

    function Chart(ctx, tx, ty) {
      this.image = new Image();

      this.update = () => {
        ctx.save();
        ctx.translate(this.x, this.y);
        ctx.rotate(this.angle * Math.PI/180);
        ctx.drawImage(this.image, -(this.size/2), -(this.size/2), this.size, this.size);
        ctx.restore();
      }

      this.reset = () => {
        this.size = Math.ceil(Math.random() * 50 + 30);
        this.x = random(0, tx - this.size);
        this.y = random(0, ty/2 - this.size);
        this.dy = 2;
        this.dx = this.x > tx/2 ? -2 : 2;
        this.vel = 0.2;
        this.angle = this.dx > 0 ? Math.round(Math.random() * 360) : Math.round(Math.random() * -360);
        this.angleInc = 4;

        this.image.onload = this.update;
        this.image.src = (() => {
          return options.images[Math.floor(Math.random() * 4)];
        })();
      }

      this.reset();
    }

    this.charts = [];
    new Timer(() => {
      if (this.charts.length < this.chartsAmount) {
        this.charts.push(new Chart(this.ctx, this.tx, this.ty));
      }
    }, 1000);

    this.animate();
  }

  resize() {
    if (this.tx != this.element.offsetWidth || this.ty != this.element.offsetHeight) {
      this.tx = this.element.offsetWidth;
      this.ty = this.element.offsetHeight;
      this.canvas.width = this.tx;
      this.canvas.height = this.ty;
    }
  }

  animate() {
    this.resize();
  
    requestAnimationFrame(this.animate.bind(this));

    this.ctx.clearRect(0, 0, this.tx, this.ty);

    for (let i = 0; i < this.charts.length; i++) {
      if (this.charts[i].x - this.charts[i].size/2 > this.tx || this.charts[i].x + this.charts[i].size/2 < 0) {
        this.charts[i].reset();
      } else {
        this.charts[i].update();
      }

      this.charts[i].x += this.charts[i].dx;
      this.charts[i].y += this.charts[i].dy;

      // Make it bounce
      if (this.charts[i].y + this.charts[i].size/2 > this.ty || this.charts[i].y < 0) {
        this.charts[i].dy = -this.charts[i].dy * this.grav;
      } else {
        this.charts[i].dy += this.charts[i].vel;

        if (this.charts[i].dx > 0) {
          this.charts[i].angle += this.charts[i].angleInc % 360;
        } else {
          this.charts[i].angle -= this.charts[i].angleInc % 360;
        }
      }

      if (this.charts[i].y + this.charts[i].size/2 > this.ty) {
        // Re-position on the base
        this.charts[i].y = this.ty - this.charts[i].size/2;

        // Make it stop bouncing
        if (this.charts[i].dy < 0 && this.charts[i].dy > -0.15) {
          this.charts[i].dy = 0;
        }
      }

      // Bounce back on X-axis
      // if (this.charts[i].x + this.charts[i].size > this.tx || this.charts[i].x < 0) {
      //   this.charts[i].dx = -this.charts[i].dx;
      // }
    }
  }
};

export default BouncingBackground;
