import { component } from "../decorators";
import { Timer } from "../utils";
import { BreakpointListener } from "../scroll/utils";
import breakpoints from "../../breakpoints";

@component("carousel")
class Carousel {
  constructor(element) {
    this.element = element;

    this.content = element.querySelector("[js-carousel-content]");

    this.progressBar = element.querySelector("[js-carousel-progress-bar]");
    this.updateProgress();

    const nextBtn = element.querySelector("[js-carousel-next-btn]");
    nextBtn.addEventListener("click", this.next.bind(this));

    const prevBtn = element.querySelector("[js-carousel-prev-btn]");
    prevBtn.addEventListener("click", this.prev.bind(this));

    this.currentIndex = 0;

    new BreakpointListener(({ screenSize }) => {
      const items = Array.from(this.content.children);

      if (screenSize === "xs" || screenSize === "sm") {
        items.forEach(
          item => (item.style.width = `${this.content.clientWidth}px`)
        );
      } else {
        items.forEach(item => (item.style.width = ""));
      }

      this.updatePosition(this.currentIndex);
      this.updateProgress();
    }, breakpoints);
  }

  next() {
    const items = this.content.children;
    const nextIndex = this.currentIndex + 1;

    if (nextIndex < items.length) {
      this.updatePosition(nextIndex);
    } else {
      this.updatePosition(0);
    }

    this.updateProgress();
  }

  prev() {
    const items = this.content.children;
    const prevIndex = this.currentIndex - 1;

    if (prevIndex >= 0) {
      this.updatePosition(prevIndex);
    } else {
      this.updatePosition(items.length - 1);
    }

    this.updateProgress();
  }

  updatePosition(index) {
    const items = this.content.children;
    this.content.style.left = `-${items[index].offsetLeft}px`;
    this.currentIndex = index;
  }

  updateProgress() {
    const total =
      this.content.scrollWidth -
      this.content.children[this.content.children.length - 1].clientWidth;
    const progress = parseInt(this.content.style.left) * -1 || 0;
    this.progressBar.style.width = `${(progress / total) * 100}%`;
  }
}

export default Carousel;
