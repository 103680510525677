import { component } from '../decorators';
import { findComponent } from '../utils';
import NavOverlay from './nav-overlay';


@component('navSidebar')
class NavSidebar {
  constructor(element) {
    this.element = element;

    const closeBtn = this.element.querySelector('[js-close-btn]');
    closeBtn.addEventListener('click', this.close.bind(this));
  }

  open() {
    this.element.classList.add('nav-sidebar--show');
    findComponent(NavOverlay).show();
  }

  close() {
    this.element.classList.remove('nav-sidebar--show');
    findComponent(NavOverlay).hide();
  }
};

export default NavSidebar;
