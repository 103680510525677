import { component } from '../decorators';
import { BreakpointListener } from '../scroll/utils';
import breakpoints from '../../breakpoints';


@component('slider')
class Slider {
    constructor(element) {
        this.prevBtn = element.querySelector('[js-slider-prev-btn]');
        this.prevBtn.addEventListener('click', this.prev.bind(this));

        this.nextBtn = element.querySelector('[js-slider-next-btn]');
        this.nextBtn.addEventListener('click', this.next.bind(this));

        this.frame = element.querySelector('[js-slider-frame]');
        this.stream = element.querySelector('[js-slider-stream]');
        this.slides = element.querySelectorAll('[js-slider-item]');

        this.currentSlide = 0;

        this.updateController();

        new BreakpointListener(() => {
            this.slides.forEach(slide => slide.style.width = `${this.frame.clientWidth}px`);
            this.stream.style.left = `-${this.currentSlide * this.frame.clientWidth}px`;
        }, breakpoints);
    }

    updateController() {
        if (this.currentSlide === 0) {
            this.prevBtn.classList.add('is-disabled');
        } else {
            this.prevBtn.classList.remove('is-disabled');
        }
        if (this.currentSlide === this.slides.length - 1) {
            this.nextBtn.classList.add('is-disabled');
        } else {
            this.nextBtn.classList.remove('is-disabled');
        }
    }

    next() {
        if (this.currentSlide < this.slides.length - 1) {
            const pos = parseInt(this.stream.style.left) || 0;
            this.stream.style.left = `${pos - this.frame.clientWidth}px`;
            this.currentSlide++;
        }
        this.updateController();
    }

    prev() {
        if (this.currentSlide > 0) {
            const pos = parseInt(this.stream.style.left) || 0;
            this.stream.style.left = `${pos + this.frame.clientWidth}px`;
            this.currentSlide--;
        }
        this.updateController();
    }
};

export default Slider;
