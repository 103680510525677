import { component } from "../decorators";
import { findComponent } from "../utils";
import NavSidebar from "./nav-sidebar";

@component("toggle")
class Toggle {
  constructor(element) {
    this.element = element;
    this.toggleInput = element.querySelector("[js-toggle-input]");
    this.element.addEventListener("change", ev => {
      if (this.toggleInput.checked) {
        this.element.classList.add("toggle--active");
      } else {
        this.element.classList.remove("toggle--active");
      }
    });
  }
}

export default Toggle;
