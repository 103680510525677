import { component } from '../decorators';
import VanillaTilt from 'vanilla-tilt';


@component('tilt')
class Tilt {
  constructor(element) {
    VanillaTilt.init(element, {
      reverse: true,
      max: 15,
      speed: 1000,
      perspective: 4000
    });
  }
};

export default Tilt;
