import { component } from '../decorators';
import { findComponent } from '../utils';
import NavSidebar from './nav-sidebar';


@component('navOverlay')
class NavOverlay {
  constructor(element) {
    this.element = element;
    this.element.addEventListener('click', function () {
      findComponent(NavSidebar).close();
    });
  }

  show() {
    this.element.classList.add('overlay-mask--show');
  }

  hide() {
    this.element.classList.remove('overlay-mask--show');
  }
};

export default NavOverlay;
