import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "lazysizes/plugins/object-fit/ls.object-fit";

import { getData, setData } from "./utils";

import Body from "./components/body";
import BouncingBackground from "./components/bouncing-background";
import Carousel from "./components/carousel";
import Circle from "./components/circle";
import CircularList from "./components/circular-list";
import Hero from "./components/hero";
import HoverList from "./components/hover-list";
import NavOverlay from "./components/nav-overlay";
import NavSidebar from "./components/nav-sidebar";
import NavTrigger from "./components/nav-trigger";
import ScrollPanel from "./components/scroll-panel";
import Slider from "./components/slider";
import Tilt from "./components/tilt";
import Toc from "./components/toc";
import Toggle from "./components/toggle";

let App = {
  components: [
    Body,
    BouncingBackground,
    Carousel,
    Circle,
    CircularList,
    Hero,
    HoverList,
    NavOverlay,
    NavSidebar,
    NavTrigger,
    ScrollPanel,
    Slider,
    Tilt,
    Toc,
    Toggle
  ]
};

(() => {
  // Bind components
  document.querySelectorAll("[data-component]").forEach(el => {
    let names = el.getAttribute("data-component").split(",");
    names.forEach(name => {
      let component = App.components.find(c => c.componentName === name);
      if (!component) {
        console.error(`[App] Component "${name}" is not defined`);
        return false;
      } else {
        let existing = getData(el) && getData(el).loadedComponents;
        if (!existing) {
          existing = [];
          setData(el, { loadedComponents: existing });
        }
        if (!(name in getData(el).loadedComponents)) {
          let options;
          try {
            options =
              eval("(" + el.getAttribute("data-component-options") + ")") || {};
          } catch (error) {
            console.error(error);
            options = {};
          }
          let Constructor = component;
          let obj = new Constructor(el, options);
          existing.push(name);
          setData(el, { component: obj });
          console.debug(`[App] Component "${name}" loaded.`);
        } else {
          console.debug(`[App] Component "${name}" already loaded`);
        }
      }
    });
  });
})();
