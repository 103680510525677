import { component } from "../decorators";
import ScrollView from "../scroll/scroll-view";
import breakpoints from "../../breakpoints";
import { findComponent } from "../utils";
import Toc from "./toc";


@component("body")
class Body {
  constructor(element) {
    let body = document.getElementsByTagName("body")[0];
    let toggleScrollingClasses = ev => {
      if (window.pageYOffset > 200) {
        body.classList.add("scrolling");
      } else {
        body.classList.remove("scrolling");
      }

      if (window.pageYOffset > 800) {
        body.classList.add("show-fixed-nav");
      } else {
        body.classList.remove("show-fixed-nav");
      }
    };
    window.addEventListener("scroll", toggleScrollingClasses);
    toggleScrollingClasses();

    const navBarHeight = document.querySelector(".nav-topbar").clientHeight;

    this.view = new ScrollView({
      container: element,
      breakpoints: breakpoints,
      scrollOffset: -navBarHeight,
      addIndicators: false
    });

    const anchors = element.querySelectorAll("[js-toc-anchor]");
    this.view.bindAnchors(anchors);

    this.view.registerSceneModifier("pin-toc", function (domScene) {
      return {
        triggerHook: "onLeave",
        offset: -navBarHeight,
        pin: domScene,
        duration: function () {
          // TODO: Improve it. This has a terrible performance. We need to make sure all images are loaded,
          // so we can get the right container height, hence, set the right scene duration.
          return document.querySelector(".toc-wrapper").offsetHeight - domScene.offsetHeight;
        }
      };
    });

    this.view.registerSceneModifier("update-toc-progress-bar", function (domScene) {
      return {
        onProgress: function(e) {
          const toc = findComponent(Toc, domScene);
          toc.setProgress(e.progress);
        },
        triggerHook: "onEnter",
        duration: function () {
          // It makes sure the images are loaded and the scene duration is right
          return domScene.offsetHeight;
        }
      };
    });

    this.view.registerSceneModifier("update-toc-section-number", function(
      domScene
    ) {
      return {
        onEnter: function () {
          const toc = findComponent(Toc, domScene.parentNode);
          toc.updateSectionNumber();
        },
        offset: -navBarHeight,
        triggerHook: "0.01", // This fixes the issue with 1px difference
        duration: function () {
          // It makes sure the images are loaded and the scene duration is right
          return domScene.offsetHeight;
        }
      };
    });

    this.view.registerSceneModifier("show-partners-map", function(
      domScene
    ) {
      return {
        onEnter: function() {
          domScene.classList.add("is-visible");
        },
        triggerHook: "0.8"
      };
    });

    this.view.registerAnimation("text-reveal", {
      duration: 1,
      ease: Power4.easeOut,
      from: {
        y: 50,
        autoAlpha: 0
      },
      to: {
        y: 0,
        autoAlpha: 1
      }
    });

    this.view.registerAnimation("text-reveal-after", {
      duration: 1,
      ease: Power4.easeOut,
      from: {
        y: 50,
        autoAlpha: 0
      },
      to: {
        y: 0,
        autoAlpha: 1
      },
      position: "-=0.75"
    });

    this.view.registerAnimation("hotspot", {
      duration: 1.5,
      ease: Elastic.easeOut.config(2.5, 0.75),
      from: {
        scale: 0.8
      },
      to: {
        scale: 1
      }
    });

    this.view.registerAnimation("image-zoom-in", {
      duration: 1,
      transition: "all 750ms ease-out",
      to: {
        scale: 1.1
      }
    });
  }

  scrollTo(targetId) {
    this.view.scrollTo(targetId);
  }
}

export default Body;
