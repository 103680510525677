import { component } from '../decorators';


@component('scrollPanel')
class ScrollPanel {
    constructor(element, options) {
        this.mode = options.mode;

        this.content = element.querySelector('[js-scroll-content]');

        this.track = element.querySelector('[js-scroll-track]');
        this.thumb = element.querySelector('[js-scroll-thumb]');

        this.content.addEventListener('scroll', this.updateScrollbarPosition.bind(this));

        window.addEventListener('resize', this.updateScrollbarSize.bind(this));

        this.updateScrollbarSize();
    }

    updateScrollbarSize() {
        if (this.mode === 'horizontal') {
            if (this.isOverflownX(this.content)) {
                this.thumb.style.width = `${ this.content.clientWidth / this.content.scrollWidth * 100}%`;
            } else {
                this.thumb.style.width = 0;
            }
        } else if (this.mode === 'vertical') {
            if (this.isOverflownY(this.content)) {
                this.thumb.style.height = `${ this.content.clientHeight / this.content.scrollHeight * 100}%`;
            } else {
                this.thumb.style.height = 0;
            }
        }
    }

    updateScrollbarPosition() {
        if (this.mode === 'horizontal') {
            const progress = this.content.scrollLeft / (this.content.scrollWidth - this.content.clientWidth);
            const thumbPos = (this.track.clientWidth * progress) - (this.thumb.clientWidth * progress);
            this.thumb.style.left = `${thumbPos}px`;
        } else if (this.mode === 'vertical') {
            const progress = this.content.scrollTop / (this.content.scrollHeight - this.content.clientHeight);
            const thumbPos = (this.track.clientHeight * progress) - (this.thumb.clientHeight * progress);
            this.thumb.style.top = `${thumbPos}px`;
        }
    }

    isOverflownX(container) {
        return container.scrollWidth > container.clientWidth
    }
    
    isOverflownY(container) {
        return container.scrollHeight > container.clientHeight;
    }
};

export default ScrollPanel;
